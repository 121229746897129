import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css"
import "../css/style.scss"
import "../styles/header.scss"
import Photo from "../components/Photo"
import {
  Link,
  useI18next,
  useTranslation,
  Trans,
  I18nextContext,
} from "gatsby-plugin-react-i18next"
import * as uuid from "uuid";
import { graphql } from "gatsby"
import Dropdown from "react-bootstrap/Dropdown"
import ChangeHistoryIcon from "@material-ui/icons/ChangeHistory"
import DetailsIcon from "@material-ui/icons/Details";
import { Auth } from "aws-amplify";
import { navigate } from "gatsby";
import { getTokenDetailsByDecoding, deleteCookie, deleteCognitoTokenFromLocalStorage, setCookie } from "../services/cookie-storage-service";
import { getWebsiteLanguages } from "../services/language-service";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { terminateUserSession } from "../services/tenant-identity-service";


const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL;
const GATSBY_LOGIPULSE_APPLICATION_LANDING_REDIRECTION_URL = process.env.GATSBY_LOGIPULSE_APPLICATION_LANDING_REDIRECTION_URL;
const GATSBY_TRIAL_APPLICATION_URL = process.env.GATSBY_TRIAL_APPLICATION_URL;
const GATSBY_LOGIPULSE_APPLICATION_URL = process.env.GATSBY_LOGIPULSE_APPLICATION_URL;

const Header = () => {
  // const context = React.useContext(I18nextContext)
  const { t } = useTranslation(); //React Hook //
  const { search } = useLocation();

  const { language } = React.useContext(I18nextContext)

  const buildCanonicalUrl = () =>
    language === "en"
      ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/header/"))
      : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + `/${language}/header/`))
  const canoncalUrl = buildCanonicalUrl();

  const buildHrefUrl = () =>
    language === "en"
      ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
      : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + `/${language}/`))
  const hrefUrl = buildHrefUrl();

  const { languages, changeLanguage } = useI18next(); // from useI18next(),Library //
  const [selectedLang, setSelectedLang] = useState("");
  const [selectedLanguageHeader, setSelectedLanguageHeader] = useState("EN");// default way of setting lang-english//
  const [isShowArrow, setIsShowArrow] = useState(false);
  const [id, setid] = useState("id1");
  const [langArray, setlanguages] = useState([]);
  const [isCurrentUserSessionExist, setCurrentSessionExist] = useState(false);


  const operations = (event, id) => {
    if (id === "id2") {
      setIsShowArrow(true)
    } else if (id === "id1") {
      setIsShowArrow(false)
    } else if (id === "product_id") {
      setIsShowArrow(!isShowArrow)
    }
  }

  const setLang = languageCode => {
    setSelectedLang(languageCode)
    changeLanguage(languageCode)
  }

  const selectLanguage = (e, languageItem) => {
    setLang(languageItem.langCode)
    localStorage.removeItem("gatsby-i18next-language")

    setSelectedLanguageHeader(languageItem.HeaderName)
  }

  const showContent = () => {
    var x = document.getElementById("navbarNav")
    if (x.style.display === "none") {
      x.style.display = "block"
    } else {
      x.style.display = "none"
    }
  }

  const outsideClick = () => {
    var x = document.getElementById("navbarNav")
    if (x.style.display === "none") {
      x.style.display = "block"
    } else {
      x.style.display = "none"
    }
  }

  useEffect(() => {
    let sessionGuid = uuid.NIL;
    let isApiSubscribed = true;
    let idTokenFromCookie = getIdTokenFromCookie();

    if(idTokenFromCookie !== "") {
      let decodedToken = getTokenDetailsByDecoding(idTokenFromCookie);

      if(decodedToken.sessionGuid !== undefined && decodedToken.sessionGuid !== null &&
         decodedToken.sessionGuid !== uuid.NIL) {
        sessionGuid = decodedToken.sessionGuid;
      }

    }

    if (idTokenFromCookie.length === 0) {
      setCurrentSessionExist(false);
    } else {
      if (isTokenExpired(idTokenFromCookie)) {
        Auth.signOut();

        terminateUserSession(sessionGuid);

        let domainName = process.env.GATSBY_DOMAIN;
        let cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];
          var eqPos = cookie.indexOf("=");
          var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.logipulse.com;";
          document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=' + domainName;
          document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=.dev.logipulse.com;';
          document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=www.dev.logipulse.com;';
        }
        setCurrentSessionExist(false);
      } else {
        setCurrentSessionExist(true);
      }
    }

    return () => {
      isApiSubscribed = false;
    };
  }, []);

  useEffect(() => {
    localStorage.removeItem("gatsby-i18next-language")

    getlanguageList()
      .then(langArray => {
        if (langArray.length > 0) {
          let selectedLanguageObject = langArray.filter(
            item => item.langCode === language
          )
          setSelectedLanguageHeader(selectedLanguageObject[0].HeaderName)
        } else {
          setSelectedLanguageHeader("EN")
        }
      })
      .catch(error => { })
  }, []);

  useEffect(() => {
    var pathname = window.location.pathname;
    var params = new URLSearchParams(search);

    if (params.has("c_id")) {
      localStorage.setItem('c_id', params.get('c_id'));
    }

    if (params.has("e_id")) {
      localStorage.setItem('e_id', params.get('e_id'));
    }

    if (pathname === "/" || pathname === "") {
      let elem = document.getElementById("main_header_portion")

      if (elem && elem.classList.contains("header_with_h")) {
        elem.classList.remove("header_with_h")
        elem.classList.add("header_without_h")
      }
    } else {
      window.onscroll = function () {
        let elem = document.getElementById("main_header_portion")
        if (window.pageYOffset <= 100) {
          if (elem != null && elem.classList.contains("header_without_h")) {
            elem.classList.add("header_with_h")
            elem.classList.remove("header_without_h")
          }
        } else {
          if (elem != null && elem.classList.contains("header_with_h")) {
            elem.classList.remove("header_with_h")
            elem.classList.add("header_without_h")
          }
        }
      }
    }
    window.scrollTo(0, 0)
  }, [])
  // languages,t
  const langArray1 = [
    { langCode: "en", displayName: "English", HeaderName: "EN" },
    { langCode: "ja", displayName: "日本語", HeaderName: "日本語" },
    { langCode: "PT", displayName: "Portugus", HeaderName: "PT" },
    { langCode: "zh-CN", displayName: "繁體中文", HeaderName: "繁體中文" },
    { langCode: "zh-hant", displayName: "繁體中文", HeaderName: "繁體中文" },
    { langCode: "fr", displayName: "Français", HeaderName: "FR" },
    { langCode: "de", displayName: "Deutsch", HeaderName: "DE" },
    { langCode: "IT", displayName: "Italiano", HeaderName: "IT" },
    { langCode: "es", displayName: "Espaol", HeaderName: "ES" },
  ]

  const getlanguageList = async () => {
    let languageList = await getWebsiteLanguages();
    
    localStorage.setItem('languages', JSON.stringify(languageList));
    let languageArr = languageList.map(obj => {
      return {
        langCode: obj.Code,
        displayName: obj.Name,
        HeaderName: obj.Code.toUpperCase(),
        // "flag":
      }
    })

    setlanguages(languageArr)

    return languageArr
  }

  const getIdTokenFromCookie = () => {
    let idToken = ""
    let cookieValue = `; ${document.cookie}`
    let splitCookieByName = cookieValue.split(`;`)

    splitCookieByName.forEach(obj => {
      if (obj[obj.search("idToken")] == "i") {
        let idTokenPositionNo = obj.search("idToken")
        idToken = obj.substr(idTokenPositionNo + 8, obj.length - 1)
      }
    })

    return idToken
  }

  const isTokenExpired = idTokenFromCookie => {
    let decodedToken = getTokenDetailsByDecoding(idTokenFromCookie);

    if (decodedToken != null) {
      let exp = decodedToken.tokenExperiation;

      return Math.floor(new Date().getTime() / 1000) >= exp;
    } else {
      return true;
    }
  }

  const signOut = async () => {
    let sessionGuid = uuid.NIL;
    let idTokenFromCookie = getIdTokenFromCookie();

    if(idTokenFromCookie !== "") {
      let decodedToken = getTokenDetailsByDecoding(idTokenFromCookie);

      if(decodedToken.sessionGuid !== undefined && decodedToken.sessionGuid !== null &&
         decodedToken.sessionGuid !== uuid.NIL) {
          sessionGuid = decodedToken.sessionGuid;
      }
    }

    Auth.signOut();

    await terminateUserSession(sessionGuid);
    await deleteCookie();
    await deleteCognitoTokenFromLocalStorage();
    setCurrentSessionExist(false)
    navigate("/login")
    // navigate(Link, { replace: true })   // gatsby,refresh the same page
  }

  const goToApplication = () => {
    let idTokenFromCookie = getIdTokenFromCookie();
    let decodedToken = getTokenDetailsByDecoding(idTokenFromCookie);
    let isTrialUser = decodedToken.isTrialUser;
    let redirectUrl = '';

    if (isTrialUser !== undefined && isTrialUser === "YES") {
      redirectUrl = `${GATSBY_TRIAL_APPLICATION_URL}${GATSBY_LOGIPULSE_APPLICATION_LANDING_REDIRECTION_URL}`;
    } else {
      redirectUrl = `${GATSBY_LOGIPULSE_APPLICATION_URL}${GATSBY_LOGIPULSE_APPLICATION_LANDING_REDIRECTION_URL}`;
    }

    setCookie("isSignIn", true);
    setCookie("isLoginIn", true);
    window.location.replace(redirectUrl);
  }

  return (

    <div
      className="header header_with_h"
      id="main_header_portion"
      lang={language}
    >
      <Helmet>
        <Link rel="canonical" href={canoncalUrl} />
        <Link rel="alternate" href={hrefUrl} hreflang={language} />
      </Helmet>
      <div className="top-head">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 pl-0 pl-md-3  text_right_other">
              <nav className="navgationbar" lang={language}>
                {!isCurrentUserSessionExist ? (
                  <span className="display_inline_block">
                    <Link to="/login/" language={language}>
                      <Trans>Sign In</Trans>
                    </Link>
                  </span>
                ) : (
                  <span className="display_inline_block">
                    <Link to="/"
                      className="app_header"
                      onClick={() => goToApplication()}
                    >
                      <Trans>Go To Application</Trans>
                      <span className="go_application"> | </span>
                    </Link>
                    <Link to="/" onClick={() => signOut()}>
                      <Trans>Sign Out</Trans>
                    </Link>
                  </span>
                )}

                <span className="display_inline_block">
                  <Link to="/purchase/"
                    language={language}
                    onClick={() => localStorage.removeItem("userinfo")}
                  >
                    <Trans>Buy License</Trans>
                  </Link>
                </span>

                <span
                  className="language_navcontent"
                  language={language}
                >
                  <Trans>Language</Trans>
                </span>

                <Dropdown
                  className="lang-dropdown-body lang-dropdown-arrow"
                  language={language}
                >
                  <Dropdown.Toggle
                    className="lang-dropdown-header"
                    language={language}
                    id="dropdown-autoclose-true"
                  >
                    {selectedLanguageHeader}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="lang-dropdown-menu dropdown-trans">
                    {langArray.map((languageItem, index) => {
                      return (
                        <Dropdown.Item
                          active={languageItem.langCode === language}
                          id={"language-drop-down-item_" + index}
                          key={"language-drop-down-item_" + index}
                          onClick={e => selectLanguage(e, languageItem)}
                        >
                          {languageItem.displayName}
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link to="/" className="navbar-brand">
                <Photo
                  src="LogiPulse-Logo-H48.svg"
                  alt=""
                  className="photo-size-header"
                />
              </Link>

              <div className="langauage-column">
                <Dropdown className="lang-dropdown-body language_nav ">
                  <Dropdown.Toggle
                    className="lang-dropdown-header"
                    language={language}
                    id="dropdown-autoclose-true"
                  >
                    {selectedLanguageHeader}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="lang-dropdown-menu">
                    {langArray.map((languageItem, index) => {
                      return (
                        <Dropdown.Item
                          active={languageItem.langCode === language}
                          key={"language-drop-down-item_mbl" + index}
                          onClick={e => selectLanguage(e, languageItem)}
                        >
                          {languageItem.displayName}
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <button
                language={language}
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => showContent()}
              >
                <div className="styled-icons">
                  <span className="navbar-toggler-icon"></span>
                </div>
              </button>

              <div className="collapse navbar-collapse " id="navbarNav">
                <div
                  className="outsideClose"
                  onClick={() => outsideClick()}
                ></div>
                <div>
                  <nav className="mbl_navigation">
                    <Link to="#" className="mbl_content">
                      <Trans>MENU</Trans>
                    </Link>

                    <button
                      language={language}
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => showContent()}
                    >
                      <span aria-hidden="true" className="image-close">
                        {" "}
                        <Photo src="close-black.svg" alt="" />
                      </span>
                    </button>
                  </nav>
                </div>
                <div className="horizontal_mbl_none"></div>
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item dropdown">
                    <Link to="/product/"
                      className="nav-link dropdown-toggles products_link_nav_mbl"
                      language={language}
                      data-toggle="dropdown"
                      onClick={e => operations(e, "product_id")}
                    >
                      <Trans>PRODUCTS</Trans>

                      {isShowArrow ? (
                        <span className="arrowdropdown">
                          <ChangeHistoryIcon
                            className="changeHistroy-icon"
                            id="id1"
                            onClick={e => operations(e, "id1")}
                          />
                        </span>
                      ) : (
                        <span className="arrowdropdown">
                          <DetailsIcon
                            className="changeHistroy-icon"
                            id="id2"
                            onClick={e => operations(e, "id2")}
                          />
                        </span>
                      )}
                    </Link>

                    <Link to="/product/"
                      className="nav-link dropdown-toggles products_link_nav_desktop"
                      language={language}
                      data-toggle="dropdown"
                      //  onClick={(e) => operations(e, "product_id")}
                      onClick={() =>
                        localStorage.setItem("productpagedata", "air")
                      }
                    >
                      <Trans>PRODUCTS</Trans>

                      {isShowArrow ? (
                        <span className="arrowdropdown">
                          <ChangeHistoryIcon
                            className="changeHistroy-icon"
                            id="id1"
                            onClick={e => operations(e, "id1")}
                          />
                        </span>
                      ) : (
                        <span className="arrowdropdown">
                          <DetailsIcon
                            className="changeHistroy-icon"
                            id="id2"
                            onClick={e => operations(e, "id2")}
                          />
                        </span>
                      )}
                    </Link>

                    {isShowArrow ? (
                      <div className="row show-on-mobile">
                        <div className="col-md-12 col-sm-6 col-lg-6 spacing-align">
                          <div className="title">
                            <Link to="/product/freight-forwarding-sofware/"
                              onClick={() =>
                                localStorage.setItem("productpagedata", "air")
                              }
                              language={language}
                            >
                              <Trans>Freight Forwarding</Trans>
                            </Link>
                          </div>

                          <ul className="list-unstyled ">
                            <li className="spacefreight">
                              <Link to="/product/air-freight-software/"
                                language={language}
                              >
                                <Photo
                                  src="KnowMore-Right Arrow-H12.svg"
                                  class="testing-arrow"
                                  language={language}
                                  alt=""
                                />{" "}
                                <Trans>Air Freight Forwarding</Trans>
                              </Link>
                            </li>

                            <li className="spacefreight">
                              <Link to="/product/ocean-freight-software/"
                                language={language}
                              >
                                <Photo
                                  src="KnowMore-Right Arrow-H12.svg"
                                  class="testing-arrow"
                                  language={language}
                                  alt=""
                                />{" "}
                                <Trans>Ocean Freight Forwarding</Trans>
                              </Link>
                            </li>

                            <li className="spacefreight">
                              <Link to="/product/land-freight-software/"
                                language={language}
                              >
                                <Photo
                                  src="KnowMore-Right Arrow-H12.svg"
                                  class="testing-arrow"
                                  language={language}
                                  alt=""
                                />{" "}
                                <Trans>Land Freight Forwarding</Trans>
                              </Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-md-12 col-sm-6 col-lg-6">
                          <div className="title">
                            <Link to="/product/warehouse-management-software/"
                              partiallyActive={true}
                              replace
                              language={language}
                            >
                              <Trans>3PL & Warehousing</Trans>
                            </Link>
                          </div>
                          <div className="titleship">
                            <Link to="/product/customs-clearance-software/"
                              partiallyActive={true}
                              replace
                              language={language}
                            >
                              <Trans>Customs Clearance</Trans>
                            </Link>
                          </div>

                          <div className="titlebrockering">
                            <Link to="/product/nvocc-software/"
                              language={language}
                            >
                              <Trans>NVOCC</Trans>
                            </Link>
                          </div>
                          <div className="titlebrockering">
                            <Link to="/features/" language={language}>
                              <Trans>Movers & Relocation</Trans>
                            </Link>
                          </div>
                          <div className="horizontal_mbl_dispay"></div>
                        </div>
                      </div>
                    ) : null}

                    <div
                      className="dropdown-menu dropdown-large dont-show-on-mobile"
                      lang={language}
                    >
                      <div className="styless"></div>

                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <div className="title">
                            <Link to="/product/freight-forwarding-sofware/"
                              onClick={() =>
                                localStorage.setItem("productpagedata", "air")
                              }
                              language={language}
                            >
                              <Trans>Freight Forwarding</Trans>
                            </Link>
                          </div>

                          <ul className="list-unstyled" lang={language}>
                            <li
                              className="spacefreight"
                              lang={language}
                            >
                              <Link to="/product/air-freight-software/"
                                language={language}
                              // onClick={() => localStorage.setItem("productpagedata", "air")}
                              >
                                <Photo
                                  src="KnowMore-Right Arrow-H12.svg"
                                  className="testing-arrow"
                                  language={language}
                                  alt=""
                                />{" "}
                                <Trans>Air Freight Forwarding</Trans>
                              </Link>
                            </li>

                            <li className="spacefreight">
                              <Link to="/product/ocean-freight-software/"
                                language={language}
                              // onClick={() => localStorage.setItem("productpagedata", "ocean")}
                              >
                                <Photo
                                  src="KnowMore-Right Arrow-H12.svg"
                                  className="testing-arrow"
                                  language={language}
                                  alt=""
                                />{" "}
                                <Trans>Ocean Freight Forwarding</Trans>
                              </Link>
                            </li>

                            <li className="spacefreight">
                              <Link to="/product/land-freight-software/"
                                language={language}
                              //  onClick={() => localStorage.setItem("productpagedata", "Land")}
                              >
                                <Photo
                                  src="KnowMore-Right Arrow-H12.svg"
                                  className="testing-arrow"
                                  language={language}
                                  alt=""
                                />{" "}
                                <Trans>Land Freight Forwarding</Trans>
                              </Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-md-6 col-sm-6 ">
                          <div className="title">
                            <Link to="/product/warehouse-management-software/"
                              language={language}
                            >
                              <Trans>3PL & Warehousing</Trans>
                            </Link>
                          </div>

                          <div className="titleship">
                            <Link to="/product/customs-clearance-software/"
                              language={language}
                            >
                              <Trans>Customs Clearance</Trans>
                            </Link>
                          </div>

                          <div className="titlebrockering">
                            <Link to="/product/nvocc-software/"
                              language={language}
                            >
                              <Trans>NVOCC</Trans>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="nav-item">
                    <Link to="/features/"
                      partiallyActive={true}
                      replace
                      className="nav-link"
                      language={language}
                    >
                      <Trans>FEATURES</Trans>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/pricing/"
                      className="nav-link"
                      language={language}
                    >
                      <Trans>PRICING</Trans>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/about-us/"
                      partiallyActive={true}
                      replace
                      className="nav-link"
                      language={language}
                    >
                      {/* <Trans>COMPANY</Trans> */}
                      <Trans>ABOUT US</Trans>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/blog/"
                      partiallyActive={true}
                      replace
                      className="nav-link"
                      language={language}
                    >
                      <Trans>RESOURCES</Trans>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/contact-us/"
                      className="nav-link"
                      language={language}
                    >
                      <Trans>CONTACT US</Trans>
                    </Link>
                  </li>
                </ul>
                <div className="horizontal_mbl_dispay"></div>
                <div>
                  <nav
                    className="mbl_navigation_display"
                    language={language}
                  >
                    {!isCurrentUserSessionExist ? (
                      <Link to="/login"
                        className="mbl_second-content"
                        language={language}
                      >
                        <Trans>Sign In</Trans>
                      </Link>
                    ) : (
                      <Link to="/"
                        className="mbl_second-content"
                        onClick={() => signOut()}
                      >
                        <Trans>Sign Out</Trans>
                      </Link>
                    )}

                    <div className="content-purchase">
                      <Link to="/purchase/"
                        language={language}
                        onClick={() => localStorage.removeItem("userinfo")}
                      >
                        <Trans>Buy License</Trans>
                      </Link>
                    </div>
                    <div className="ml_faq">{/* <Trans>FAQ</Trans> */}</div>
                  </nav>
                </div>

                <div className="horizontal_mbl_dispay"></div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className="fixed-link" lang={language}>
        <Link to="/free-demo/" lang={language}>
          <div className="rotate">
            <Trans>GET A FREE DEMO</Trans>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default Header

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
